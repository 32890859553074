.btn {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    border: none;
    cursor: pointer;
}

.btn-4::before,
.btn-4::after,
.btn.hover-border-6::before,
.btn.hover-border-6::after,
.btn.hover-border-6 span::before,
.btn.hover-border-6 span::after,
.btn.hover-border-7::before,
.btn.hover-border-7::after,
.btn.hover-border-7 span::before,
.btn.hover-border-7 span::after {
    position: absolute;
    content: "";
}

.btn-4::before,
.btn-4::after {
    width: 15%;
    height: 2px;
    background-color: rgb(54, 56, 55);
    z-index: 2;
}

.btn.hover-border-6::before,
.btn.hover-border-6::after {
    top: 0;
    transition: width 0.2s 0.35s ease-out;
}
.btn.hover-border-6::before {
    right: 50%;
}
.btn.hover-border-6::after {
    left: 50%;
}
.btn.hover-border-6:hover::before,
.btn.hover-border-6:hover::after {
    width: 50%;
    transition: width 0.2s ease-in;
}

.btn.hover-border-6 span::before,
.btn.hover-border-6 span::after {
    width: 0%;
    height: 0%;
    background: transparent;
    opacity: 0;
    z-index: 2;
    transition: width 0.2s ease-in, height 0.15s 0.2s linear, opacity 0s 0.35s;
}
.btn.hover-border-6 span::before {
    top: 0;
    left: 0;
    border-left: 2px solid rgb(54, 56, 55);
    border-bottom: 2px solid rgb(54, 56, 55);
}
.btn.hover-border-6 span::after {
    top: 0;
    right: 0;
    border-right: 2px solid rgb(54, 56, 55);
    border-bottom: 2px solid rgb(54, 56, 55);
}
.btn.hover-border-6 span:hover::before,
.btn.hover-border-6 span:hover::after {
    width: 50%;
    height: 96%;
    opacity: 1;
    transition: height 0.2s 0.2s ease-in, width 0.2s 0.4s linear, opacity 0s 0.2s;
}

.btn.hover-border-7::before,
.btn.hover-border-7::after {
    bottom: 0;
    transition: width 0.2s 0.35s ease-out;
}
.btn.hover-border-7::before {
    right: 50%;
}
.btn.hover-border-7::after {
    left: 50%;
}
.btn.hover-border-7:hover::before,
.btn.hover-border-7:hover::after {
    width: 50%;
    transition: width 0.2s ease-in;
}

.btn.hover-border-7 span::before,
.btn.hover-border-7 span::after {
    width: 0%;
    height: 0%;
    background: transparent;
    opacity: 0;
    z-index: 2;
    transition: width 0.2s ease-in, height 0.15s 0.2s linear, opacity 0s 0.35s;
}
.btn.hover-border-7 span::before {
    bottom: 0;
    left: 0;
    border-left: 2px solid rgb(54, 56, 55);
    border-top: 2px solid rgb(54, 56, 55);
}
.btn.hover-border-7 span::after {
    bottom: 0;
    right: 0;
    border-right: 2px solid rgb(54, 56, 55);
    border-top: 2px solid rgb(54, 56, 55);
}
.btn.hover-border-7 span:hover::before,
.btn.hover-border-7 span:hover::after {
    width: 50%;
    height: 96%;
    opacity: 1;
    transition: height 0.2s 0.2s ease-in, width 0.2s 0.4s linear, opacity 0s 0.2s;
}
