:root {
    --balloon1-bg-color: #eaeaeae6;
    --balloon2-bg-color: #e8dab2e6;
    --balloon3-bg-color: #dd6e42e3;
    --balloon4-bg-color: #4f6d7ad6;
    --balloon5-bg-color: #eaeaeae6;
    --balloon1-bg-color: #cff0c9;
    --balloon2-bg-color: #d6e300;
    --balloon3-bg-color: #eb5d00;
    --balloon4-bg-color: #009264;
    --balloon5-bg-color: #cdf1c9;
    --bg-color: #c0d6df;

    --balloon-anim-1: balloon1 15s ease infinite;
    --balloon-anim-2: balloon2 5s ease infinite;
    --balloon-anim-3: balloon3 10s ease infinite;
    --balloon-anim-4: balloon4 5s ease infinite;
    --balloon-anim-5: balloon5 15s ease infinite;
    --balloon-anim-6: balloon7 5s ease infinite;

    --ballon-height: 50px;
    --ballon-margin: 10px 3px;
    --ballon-balf-bottom: -9px;
    --ballon-balf-left: 16px;
    --animation-margin: 0.3;
}

@media (min-width: 576px) {
    :root {
        --ballon-height: 80px;
        --ballon-margin: 15px 5px;
        --ballon-balf-bottom: -14px;
        --ballon-balf-left: 25px;
        --animation-margin: 0.6;
    }
}

@media (min-width: 768px) {
    :root {
        --ballon-height: 100px;
        --ballon-margin: 10px;
        --ballon-balf-bottom: -16px;
        --ballon-balf-left: 31px;
        --animation-margin: 1;
    }
}

.happy,
.birthday {
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.balloon {
    width: calc(var(--ballon-height) * 3 / 4);
    height: calc(var(--ballon-height));
    margin: var(--ballon-margin);
    border-radius: 80%;
    position: relative;
}

.balloon::after {
    position: absolute;
    bottom: var(--ballon-balf-bottom);
    left: var(--ballon-balf-left);
    content: "\2713";
    transform: rotate(180deg);
    font-weight: bold;

    font-size: calc(var(--ballon-height) / 25 * 4);
}

.happy .balloon::before,
.birthday .balloon::before {
    position: absolute;
    transform: translate(-50%, -50%);
    top: calc(50%);
    left: calc(50%);
    font-size: 30px;
    color: #fff;
    font-family: "Lemonada", sans-serif;
}

.happy .balloon:nth-child(1)::before {
    content: "H";
}
.happy .balloon:nth-child(2)::before {
    content: "A";
}
.happy .balloon:nth-child(3)::before {
    content: "P";
}
.happy .balloon:nth-child(4)::before {
    content: "P";
}
.happy .balloon:nth-child(5)::before {
    content: "Y";
}
.birthday .balloon:nth-child(1)::before {
    content: "B";
}
.birthday .balloon:nth-child(2)::before {
    content: "I";
}
.birthday .balloon:nth-child(3)::before {
    content: "R";
}
.birthday .balloon:nth-child(4)::before {
    content: "T";
}
.birthday .balloon:nth-child(5)::before {
    content: "H";
}
.birthday .balloon:nth-child(6)::before {
    content: "D";
}
.birthday .balloon:nth-child(7)::before {
    content: "A";
}
.birthday .balloon:nth-child(8)::before {
    content: "Y";
}
@keyframes balloon1 {
    0%,
    100% {
        transform: translate(0, 0) rotate(-10deg);
    }
    50% {
        transform: translate(0, calc(var(--animation-margin) * 30px)) rotate(10deg);
    }
}

@keyframes balloon2 {
    0%,
    100% {
        transform: translate(
                calc(var(--animation-margin) * -20px),
                calc(var(--animation-margin) * 40px)
            )
            rotate(15deg);
    }
    50% {
        transform: translate(0, calc(var(--animation-margin) * -10px)) rotate(-5deg);
    }
}

@keyframes balloon3 {
    0%,
    100% {
        transform: translate(calc(var(--animation-margin) * -30px), 0) rotate(-10deg);
    }
    50% {
        transform: translate(0, calc(var(--animation-margin) * -20px)) rotate(20deg);
    }
}

@keyframes balloon4 {
    0%,
    100% {
        transform: translate(calc(var(--animation-margin) * -10px), 0) rotate(0deg);
    }
    50% {
        transform: translate(0, calc(var(--animation-margin) * 20px)) rotate(30deg);
    }
}

@keyframes balloon5 {
    0%,
    100% {
        transform: translate(calc(var(--animation-margin) * 10px), 0) rotate(20deg);
    }
    50% {
        transform: translate(0, calc(var(--animation-margin) * 30px)) rotate(-20deg);
    }
}
@keyframes balloon6 {
    0%,
    100% {
        transform: translate(calc(var(--animation-margin) * -10px), 0) rotate(0deg);
    }
    50% {
        transform: translate(0, calc(var(--animation-margin) * 20px)) rotate(30deg);
    }
}

@keyframes balloon7 {
    0%,
    100% {
        transform: translate(0, 0) rotate(15deg);
    }
    50% {
        transform: translate(0, 0) rotate(-15deg);
    }
}
.balloon1 {
    background: var(--balloon1-bg-color);
    box-shadow: inset 10px 10px 15px #0000002a, 15px 15px 20px #0005;
}
.balloon1::after {
    color: var(--balloon1-bg-color);
}

.balloon2 {
    background: var(--balloon2-bg-color);
    box-shadow: inset 10px 10px 15px #0000002a, 15px 15px 20px #0005;
}
.balloon2::after {
    color: var(--balloon2-bg-color);
}

.balloon3 {
    background: var(--balloon3-bg-color);
    box-shadow: inset 10px 10px 15px #0000002a, 15px 15px 20px #0005;
}
.balloon3::after {
    color: var(--balloon3-bg-color);
}

.balloon4 {
    background: var(--balloon4-bg-color);
    box-shadow: inset 10px 10px 15px #0000002a, 15px 15px 20px #0005;
}
.balloon4::after {
    color: var(--balloon4-bg-color);
}
.balloon5 {
    background: var(--balloon5-bg-color);
    box-shadow: inset 10px 10px 15px #0000002a, 15px 15px 20px #0005;
}
.balloon5::after {
    color: var(--balloon5-bg-color);
}

.balloon-anim1 {
    animation: var(--balloon-anim-1);
}
.balloon-anim2 {
    animation: var(--balloon-anim-2);
}
.balloon-anim3 {
    animation: var(--balloon-anim-3);
}
.balloon-anim4 {
    animation: var(--balloon-anim-4);
}
.balloon-anim5 {
    animation: var(--balloon-anim-5);
}
.balloon-anim6 {
    animation: var(--balloon-anim-6);
}
