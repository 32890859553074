:root {
    --font-family: "Almarai";
    --side-navbar-collapsed-width: 3rem;
    /* --body-top: 0px; */
}
@font-face {
    font-family: "FS Albert Arabic";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/fs-albert.ttf") format("truetype");
}
@font-face {
    font-family: "FS Albert Arabic";
    font-weight: bold;
    font-style: normal;
    src: url("./assets/fonts/fs-albert-700.ttf") format("truetype");
}
@font-face {
    font-family: "Somar";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Somar";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Somar";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Kufam";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/Kufam-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Kufam";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/Kufam-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Kufam";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/Kufam-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "DG-Forsha";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/DG\ Forsha\ Regular.ttf") format("truetype");
}

@font-face {
    font-family: "DG-Forsha-Scribble";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/DG\ Forsha\ Scribble.ttf") format("truetype");
}
@font-face {
    font-family: "RadyPro";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/Abd\ ElRady\ Pro\ Regular.otf") format("truetype");
}

@font-face {
    font-family: "RadyPro";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/Abd\ ElRady\ Pro.otf") format("truetype");
}

.font-lama {
    font-family: Lama sans, sans-serif;
}

.font-kuf {
    font-family: "Kufam", sans-serif;
}
.font-som {
    font-family: "Somar", sans-serif;
}

/* body {
    height: 1000vh;
    direction: ltr;
} */
.pisitive-nav-top {
    top: calc(var(--navbar-height));
}

.font-forsha {
    font-family: "DG-Forsha", sans-serif;
}
.font-Scribble {
    font-family: "DG-Forsha-Scribble", sans-serif;
}
.font-rady {
    font-family: "RadyPro", sans-serif;
}

.font-lem {
    font-family: "Lemonada", sans-serif;
}

.text-stroke {
    --s: 1px;
    --sclr: #000;
    text-shadow: 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr),
        0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr),
        0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr),
        0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr),
        0 0 var(--s) var(--sclr);
}

@supports (-webkit-text-stroke: 1px theme("colors.black")) {
    .text-stroke-transparent {
        -webkit-text-stroke: 1px theme("colors.black");
        transition: all 0.4s ease;
    }
    .darkmode .text-stroke-transparent {
        -webkit-text-stroke: 1px theme("colors.sakrBlue.500");
    }
}
