.path-vert {
    stroke-dasharray: 1000;
    /*   stroke-dashoffset: 800; */
    animation: draw1 3s linear alternate;
}

@keyframes draw1 {
    0% {
        stroke-dashoffset: 1200;
    }
    33.33% {
        stroke-dashoffset: 0;
    }
    71.66% {
        stroke-dashoffset: 1200;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
