.table-study-style {
    border-radius: 12px;
    border-collapse: separate;
    border-spacing: 0;
}

.table-study-style td,
.table-study-style th {
    border: 1px solid var(--color-text-secondary);
    transition: all var(--transition-smooth);
    padding: 8px;
}

.fade-down-in {
    animation: fadeDownIn 0.5s ease-in-out;
    opacity: 1;
}

.fade-up-out {
    animation: fadeUpOut 0.5s ease-in-out;
    opacity: 0;
}

@keyframes fadeDownIn {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeUpOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-10%);
    }
}
