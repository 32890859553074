.main__svg-wrapper {
    z-index: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    height: auto;
    /* transform: translateX(-100px); */
}
.main__hex-wrapper {
    position: absolute;
    width: 130px;
    top: 75px;
    right: 0px;
    height: auto;
}

#bottom1,
#bottom2,
#top,
.main__image {
    transition: all 0.3s ease;
}

.main__image__wrapper img {
    max-width: 100%;
    height: auto;
}
.courses .main__image__wrapper img {
    opacity: 0.5;
}
.main__title {
    font-size: 6rem;
}

.main__text-wrapper {
    height: 100%;
}
.main__title__first {
    position: relative;
}
.main__title__first.writing::after {
    content: "";
    height: 6rem;
    position: absolute;
    /* top: 2rem; */
    top: 0px;
    left: 0px;
    border: 3px solid var(--color-text-secondary);
}
.main__content {
    color: var(--color-text-secondary);
    font-weight: var(--font-w-medium);
    font-size: var(--font-size-h1);
}
.main__content span:nth-child(1) {
    /* color: var(--color-red-dark-1); */
}
.main__content span:nth-child(2) {
    /* color: var(--color-blue-dark-1); */
}

.main__classes__title {
    position: relative;
}
.main__classes__title__text {
    width: 150px;
    position: absolute;
    left: 50%;
    margin-left: -75px;
    top: -15px;
    background-color: var(--color-primary-container);
    box-shadow: 0px 0px 40px var(--color-primary-container);
}
.main__classes__title hr {
    height: 2px;
    /* background-color: var(--color-purple-dark-1); */
}
.darkmode .main__classes__title hr {
    height: 1px;
    /* background-color: var(--color-purple-dark-1); */
}
.main__classes__buttons {
    z-index: 10;
}
.main__classes__wrapper a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 0px 5px;
}
.main__classes__outer__circle {
    position: absolute;
    top: 0px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 1px dashed var(--color-text-primary);
    animation-name: spin;
    animation-duration: 20000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: reverse;
}
.main__classes__wrapper a:hover .main__classes__outer__circle {
    animation-duration: 2000ms;
}
.main__classes__circle {
    font-weight: var(--font-w-medium);
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
}
.main__classes__circle__icon {
    position: absolute;
    font-size: var(--font-size-h1);
    opacity: 0.18;
}
.main__classes__circle span {
    font-size: var(--font-size-h2);
    text-shadow: 1px 1px 3px #fffa;
}

.main__spinning .iconify {
    font-size: var(--font-size-h1);
    animation-name: coloredSpin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes coloredSpin {
    0% {
        /* color: var(--color-purple-dark-1); */
        color: theme("colors.purple.500");
        transform: rotate(0deg);
    }
    20% {
        /* color: var(--color-red-dark-1); */
        color: theme("colors.rose.500");
    }
    40% {
        /* color: var(--color-blue-dark-1); */
        color: theme("colors.blue.500");
    }
    60% {
        /* color: var(--color-yellow-dark-1); */
        color: theme("colors.yellow.500");
    }
    80% {
        /* color: var(--color-pink-dark-1); */
        color: theme("colors.pink.500");
    }
    100% {
        /* color: var(--color-purple-dark-1); */
        color: theme("colors.purple.500");
        transform: rotate(360deg);
    }
}

.paper-border {
    /* transform: translateY(var(--translate-top));
        margin-bottom: var(--translate-top); */
    z-index: -1;
}
#lower-border {
    opacity: 0.5;
    filter: drop-shadow(0px 4px 2px #0005);
}

.mid__img__wrapper {
    width: 100%;
    text-align: center;
}
.mid-section img {
    max-width: 70%;
    height: auto;
}
.mid__img__wrapper.shake img {
    animation: shake;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}

.mid__text {
    margin-top: 1rem;
    padding: 1rem;
    width: 80%;
    text-align: center;
    border-radius: 0.4rem;
    transition: all 0.4s ease;
    color: var(--white);
}

.mid__text.stable,
.mid__card:nth-child(2) .mid__text.stable,
.mid__card:nth-child(3) .mid__text.stable {
    background-color: var(--color-primary-container);
    box-shadow: 0px 0px 20px #000;
    color: var(--color-text-primary);
}

.bg-star {
    background-image: url("../../assets/Group8.png");
    background-repeat: repeat;
    background-position: center top;
    background-size: cover;
}

.box-shadow-yearCard {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.1s linear, transform 0.15s ease-out;
}
.box-shadow-yearCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12),
        0 7px 8px -4px rgba(0, 0, 0, 0.2);
}
